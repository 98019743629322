<template>
  <v-list-item :three-line="Boolean(value.description)">
    <v-list-item-avatar
      ><v-icon>{{ icon }}</v-icon></v-list-item-avatar
    >
    <v-list-item-content>
      <v-list-item-subtitle>
        <DateValue :value="value.date"></DateValue>,
        {{ value.time.substr(0, 8) }}</v-list-item-subtitle
      >
      <v-list-item-title>
        {{ value.type.description }}
      </v-list-item-title>
      <v-list-item-subtitle v-if="value.description">
        {{ value.description }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import DateValue from "common/components/DateValue.vue";

export default {
  name: "ChangeItem",
  components: { DateValue },
  props: {
    value: null,
  },
  computed: {
    icon() {
      switch (this.value.type.code) {
        case "accountActivate":
          return "mdi-account-check";
        case "accountDeactivate":
          return "mdi-account-off";
        case "addressChange":
          return "mdi-map-marker";
        case "birthdayChange":
          return "mdi-cake";
        case "citizenshipChange":
          return "mdi-passport";
        case "classTeacherAdd":
          return "mdi-account-plus";
        case "classTeacherRemove":
          return "mdi-account-minus";
        case "divisionChange":
          return "mdi-account-switch";
        case "employeeCategoryChange":
          return "mdi-account-switch-outline";
        case "employeeNumberChange":
          return "mdi-identifier";
        case "firstNameChange":
          return "mdi-tag";
        case "genderChange":
          return "mdi-gender-transgender";
        case "ibanChange":
          return "mdi-bank";
        case "lastNameChange":
          return "mdi-tag";
        case "mailHomeChange":
          return "mdi-email";
        case "municipalityChange":
          return "mdi-map";
        case "namesakeNumberChange":
          return "mdi-counter";
        case "officialFirstNameChange":
          return "mdi-tag";
        case "passwordReset":
          return "mdi-lock-check";
        case "passwordResetRequest":
          return "mdi-lock-remove";
        case "phoneHomeChange":
          return "mdi-phone-classic";
        case "phoneMobileChange":
          return "mdi-cellphone";
        case "phoneWorkChange":
          return "mdi-phone";
        case "placeOfOriginChange":
          return "mdi-home-map-marker";
        case "professionChange":
          return "mdi-account-hard-hat";
        case "socialSecurityNumberChange":
          return "mdi-card-account-details";
        case "studentStatusChange":
          return "mdi-account-school";
        case "substituteClassTeacherAdd":
          return "mdi-account-plus-outline";
        case "substituteClassTeacherRemove":
          return "mdi-account-minus-outline";
        case "titleChange":
          return "mdi-tag";
        default:
          return "";
      }
    },
  },
};
</script>
