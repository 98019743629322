<template>
  <v-list>
    <ChangeItem
      v-for="(item, index) in items"
      :key="'change' + index"
      :value="item"
    >
    </ChangeItem>
  </v-list>
</template>
<script>
import ChangeItem from '@/components/ChangeItem.vue';

export default {
  name: 'ChangeLog',
  props: {
    id: { type: String },
  },
  data() {
    return {
      items: [],
    };
  },
  components: { ChangeItem },
  watch: {
    id() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      if (!this.id) {
        return;
      }
      this.items = await this.apiGet({
        resource: 'person/changelog',
        id: this.id,
      });
    },
  },
  async created() {
    this.fetchData();
  },
};
</script>
